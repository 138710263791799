/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.SearchField {
    @include mobile {
        margin: unset;
    }

    &-Input {
        height: var(--header-nav-height);
        border: none;
        background-color: var(--header-button-color);
        color: var(--search-input-default-color);
        caret-color: var(--color-white);

        &:focus {
            color: var(--color-white);
        }
    }

    .SearchIcon {
        fill: var(--color-white);
    }

    .CloseIcon {
        fill: var(--color-white);
    }
}
