/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../hotme-theme/src/style/abstract/variables';
@import '../../../../hotme-theme/src/style/abstract/media';
@import '../../../../hotme-theme/src/style/abstract/button';
@import '../../../../hotme-theme/src/style/abstract/loader';
@import '../../../../hotme-theme/src/style/abstract/icons';
@import '../../../../hotme-theme/src/style/abstract/parts';
@import '../../../../hotme-theme/src/style/abstract/variables.override';
@import '../../../../hotme-theme/src/style/abstract/button.override';

:root {
    --ampromo-overlay__background-color: rgba(0, 0, 0, 0.8);
    --ampromo-items__background-color: #fff;
    --slick-button__size: 42px;
    --slick-button__distance: calc(var(--slick-button__size) + 3);
    --slick-dot__height: 20px;
    --slick-dot__width: var(--slick-dot__height);
}

.slick-slider {
    .slick-arrow {
        width: 0;
        height: 0;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 14px solid var(--primary-base-color);
    }
}

.AmastyGift-Popup {

    @include mobile {
        height: auto;
        max-height: 750px;
    }

    .Popup-Content {
        width: 100%;
        max-width: 960px;
        max-height: auto;
        overflow-x: hidden;

        @include desktop {
            max-height: 90%;
        }

        @include narrow-desktop {
            width: 100%;
        }
    }

    .Popup-Heading {
        font-size: 24px;
        text-align: center;
    }

    .AmastyGiftItem-Figure {
        max-height: 300px;

        img {
            max-height: 280px;
        }
    }
}

.AmastyGiftPopup {
    width: 100%;
    height: 100%;
}

.ampromo-items-content {
    width: 100%;
    text-align: start;
    background-color: var(--ampromo-items__background-color);

    .ampromo-popup-title,
    .ampromo-item-buttons,
    .ampromo-title {
        text-align: center;
    }

    .ampromo-popup-title {
        font-weight: 300;
        margin: 0 auto 20px;
    }

    .ampromo-popup-title .ampromo-counter {
        font-weight: bold;
    }

    .ampromo-item {
        position: relative;
        box-sizing: border-box;
        margin: 0 8px 7px;
        border: var(--ampromo-item__border);
        transition: 0.6s;

        &:hover {
            background: #ccc;
        }
    }

    .ampromo-item-image {
        display: block;
        margin: 7px auto;
    }

    .ampromo-title {
        margin: 0;
    }

    .ampromo-item-buttons {
        @include mobile {
            padding: 20px 0;
        }
    }
}

.ampromo-slick {
    // Override slick styles

    .slick-track {
        @include desktop {
            margin-block-end: 25px;
        }
    }

    .slick-slide {
        vertical-align: top;
    }

    .slick-dots {
        position: static;
        padding: 8px 0;

        @include desktop {
            inset-block-end: 0;
        }

        li {
            &::before {
                &,
                ul > & {
                    content: '';
                }
            }

            button {
                &::before {
                    color: var(--primary-base-color);
                }
            }

            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }
}

.ampromo-items-form {
    background: #fff;
    padding: 5px;

    .ampromo-item-qty-input {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .ampromo-item-qty-left {
        font-size: 14px;
    }

    .product-info-price {
        padding-block-end: 10px;
        text-align: center;
        word-break: break-all;
    }

    .price-base-price .price {
        font-size: 25px;
        text-decoration: line-through;
    }

    .price-new-price .price-wrapper {
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;

        @media only screen and (max-width: 400px) {
            font-size: 30px;
            line-height: 30px;
        }
    }


}

@media only screen and (max-width: 400px) {
    // rewrite slick styles
    .ampromo-items-content {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 300px) {
    .slick-slider {
        padding: 0;
    }

    .ampromo-items-content {
        padding: 0;
    }
}
