/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 * Original source: vendor/magefan/module-blog/view/frontend/web/css
 * Most Parts of these styles are copied from original extension Magefan Blog
 */
// TODO: Migrate these when you're going to bring more features from original extension

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.post-list > .post-holder {
    padding-block-end: 20px;
    margin-block-end: 20px;
}

.post-list > .post-holder::before {
    content: none;
}


.columns {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.column .main {
    min-height: 300px;
    box-sizing: border-box;
    padding-block-end: 40px;
    flex-basis: auto;
    flex-grow: 1;
}

.Blog-Content.column {
    padding-block-end: 40px;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100%;
    box-sizing: border-box;
}

@media (min-width: 768px), print {
    .columns {
        display: block;
    }
}

@media (min-width: 768px), print {
    .Blog-Content.column {
        min-height: 300px;
        box-sizing: border-box;
        width: 79.16666667%;
        float: inline-start;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
}
/* stylelint-disable declaration-no-important */


.blog-page-list .post-holder {border-bottom: 1px solid #eee}

.post-holder .post-header {position: relative;}

.post-holder .post-header .post-info {padding: 0 0 20px;}

.post-holder .post-header .post-info .item {
    display: inline-block;
    margin-inline-start: 5px;
    margin-inline-end: 2px;
}

.post-holder .post-header .post-info .dash {display: none; /* fix for old templates */}

.post-holder .post-header .post-info .label {font-weight: 700;}

.post-holder .post-header .post-info .value {font-style: italic;}

.post-holder .post-header .post-info .item::after {
    display: inline-block;
    content: ' | ';
    color: #b9b9b9;
    margin-inline-start: 8px;
}

.post-holder .post-header .post-info .item:last-child::after {display: none}

.post-list {
    margin-block: 0px 20px;
    list-style: none;
}

.post-list-wrapper .post-list {padding-inline-start: 0}

.post-list .post-content .post-read-more {padding-block-start: 10px; float: inline-end;}

.subcategory-links-wrapper ul,
.post-links-wrapper ul {list-style: none}

.post-content .post-description img {margin: 15px 0;max-width: 100%;height: auto}

.post-content .post-description ul,
.post-content .post-description ol {list-style-position: outside;}

.post-content .post-description .mfblog-contents li {display: list-item;}

.post-content .post-description .mfblog-contents li li {margin-inline-start: 15px;}

.post-content .post-description .post-ftimg-hld {margin: 0 0 10px;}

.post-content .post-description .post-ftimg-hld img {width: 100%; padding: 0; margin: 0;}

.post-content .post-description .mfblog-contents-toc {list-style: decimal;}

.post-content .post-description .mfblog-contents-toc li {margin-block-end: 1rem;}

.post-content .post-description .mfblog-contents-toc li > ol {margin-block-start: 1rem;}

.post-content .post-description .mfblog-contents-toc li::before {content: none;}

.post-content .post-description .mfblog-contents-toc li a {display: inline;}


/* Post Title */

.post-list .post-header .post-title-holder {display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap;}

.post-list .post-header .post-title {margin: 0;padding: 10px 0;}

.post-list .post-header .post-title a {text-decoration: none;font-size: 22px;font-weight: 400;}

.post-list .post-holder .post-header .addthis_toolbox a {margin-block-end: 0;}

.post-list .hidden {display: none;}

.post-view .post-bottom {margin-block-start: 30px;}

.post-view .post-nextprev-hld {margin-block-end: 30px;}

.post-view .post-nextprev-hld .nextprev-link {font-weight: 700;}

.post-view .post-nextprev-hld .prev-link {float: inline-start}

.post-view .post-nextprev-hld .next-link {float: inline-end}

/* Post Gallery */

.post-view .post-gallery {margin: 15px 0}

.post-view .post-gallery .gallery-image-hld {margin: 0 14px 14px 0; border: 1px solid #ccc; display: block; float: inline-start}

.post-view .post-gallery .gallery-image {padding: 0; margin: 0; display: block}

.post-view .block {margin-block-end: 30px;}

/* Widget Gallery */

.widget.block-categories ul {padding: 0;margin: 0;list-style: none;}

.widget.block-categories ul li {padding: 5px 0 5px 9px;margin: 0;}

.widget.block-categories ul li a {font-weight: 600;}

.widget.block-categories ul ul {margin: 0;}

/* Widget RSS */

.widget.block-rss .rss-icon {
    float: inline-start;
    padding-inline-end: 10px;
    width: 23px;
    filter: grayscale(100%);
    vertical-align: middle;
}

/* Widget Search */

.widget.blog-search {width: 100%;margin-block-end: 40px;position: relative;float: inline-end;}

.widget.blog-search .block-content {margin-block-end: 0;}

.widget.blog-search .block-content .form {position: relative;}

.widget.blog-search label {border: 0;clip: rect(0, 0, 0, 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;text-decoration: none;display: inline-block;float: inline-end;}

.widget.blog-search .control {border-top: 0;margin: 0;padding: 0;clear: both;}

.widget.blog-search input {position: static;padding-inline-end: 35px;margin: 0;inset-inline-start: -300%;}

.widget.blog-search .action.search::before {-webkit-font-smoothing: antialiased;content: '';margin: 0;vertical-align: top;display: inline-block;overflow: hidden;speak: none;width: 16px;height: 32px;}

.widget.blog-search .action.search > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* Widget Tag Claud */

.widget.block-tagclaud .smallest {font-size: 0.75em}

.widget.block-tagclaud .small {font-size: 1em}

.widget.block-tagclaud .medium {font-size: 1.25em}

.widget.block-tagclaud .large {font-size: 1.5em}

.widget.block-tagclaud .largest {font-size: 2em}

/* Widget Archive */

.widget.block-archive .item {margin: 5px 0;}


.widget.block-archive .item::after {content: "\\"; display: inline-block;padding-inline-start: 5px}

/* Widget Recent */

.blog-widget-recent .post-list {padding: 0;margin: 0;}

.blog-widget-recent .post-holder {width: 31.2%;float: inline-start;margin: 1%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}

.post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder {width: 48%;}

.post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder:nth-child(2n+1) {clear: both;}

@media (max-width: 767px) {
    .post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder {width: 98%;}
}

@media (max-width: 1024px) {
    .blog-widget-recent .post-holder {
        width: 48%;
    }
}

@media (max-width: 767px) {
    .blog-widget-recent .post-holder {
        width: 98%;
    }
}

.blog-widget-recent .post-description .post-text-hld {
    min-height: 150px;
    overflow-y: auto;
}

.blog-widget-recent-masonry .post-description .post-text-hld {
    max-height: none;
    min-height: 0;
}

.blog-widget-recent-masonry .post-description .post-ftimg-hld {
    width: 100%;
    margin: 0;
}

.mfblog-autoloader {text-align: center;}

.mfblog-autoloader .mfblog-show-onload {display: none;}


@media (max-width: 420px) {
    #mfblog_gcomments {max-width: 345px; overflow-y: scroll;}
}

.blog-category-view .category-content,
.blog-tag-view .tag-content {padding-inline-start: 0}

/* Posts List */

.widget.block-list-posts .block-content .item {
    padding: 8px 0 0;
}

.widget.block-list-posts .block-content .item .post-image {
    width: 60px;
    float: inline-start;
    border-radius: 0;
    padding: 2px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    margin-inline-end: 12px;
}

.widget.block-list-posts .block-content .item .post-image .post-item-link {
    display: inline-block;
}

.widget.block-list-posts .block-content .item .post-image .post-item-link img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}
/* END Posts List */

/* Fix Related Products */

.blog-post-view .products-grid .product-item {
    display: inline-block;
    margin-inline-start: 2%;
    width: calc((100% - 2%) / 2);
}

.blog-post-view .product-item:nth-child(2n+1) {
    margin-inline-start: 0;
}

.blog-post-view .products-grid .product-item .product-item-info {
    width: auto;
}

@media (min-width: 640px) {
    .blog-post-view .products-grid .product-item {
        display: inline-block;
        margin-inline-start: 2%;
        width: calc((100% - 4%) / 3);
    }

    .blog-post-view .products-grid .product-item:nth-child(2n+1) {
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(3n+1) {
        margin-inline-start: 0;
    }
}

@media (min-width: 1024px) {
    .blog-post-view .products-grid .product-item {
        width: calc((100% - 8%) / 5);
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(3n+1) {
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(5n+1) {
        margin-inline-start: 0;
    }
}

/* Icons */

.mf-blog-icon {width: 15px;height: 15px;display: inline-block;position: relative;inset-block-start: 2px;background-size: cover;}

.mf-blog-icon.mfbi-calendar {background-image: url('../../style/icons/calendar-icon.svg')}

.mf-blog-icon.mfbi-folder {background-image: url('../../style/icons/folder-icon.svg')}

.mf-blog-icon.mfbi-comments {background-image: url('../../style/icons/comments-icon.svg')}

.mf-blog-icon.mfbi-tags {background-image: url('../../style/icons/tags-icon.svg')}

.mf-blog-icon.mfbi-user {background-image: url('../../style/icons/user-icon.svg')}

.mf-blog-icon.mfbi-views {background-image: url('../../style/icons/views-icon.svg')}
/* END Fix Related Products */


/* All Widget Templates ************************************************************************************************
***********************************************************************************************************************/

body.blog-page {
    --color-white: #ffffff;
    --color-green: #28a745;
    --color-yellow: #ffc107;
    --color-blue: #4db2ec;
    --color-black: #222222;
    --color-gray: #6c757d;
    --color-red: #dc3545;
    --border-color: #d5d6da;
}

.fav-color-green {color: #28a745;}

.fav-color-yellow {color: #ffc107;}

.fav-color-blue {color: #4db2ec;}

.fav-color-black {color: #222222;}

.fav-color-gray {color: #6c757d;}

.fav-color-red {color: #dc3545;}


/* Buttons */

.btn-white {background-color: #fff !important;color: #7d7d7d !important;border: 1px solid #bbb !important;}

.btn-white:hover {background-color: #4db2ec !important;color: #fff !important; border: 1px solid #4db2ec !important;}

.btn-black {background-color: #222 !important;color: #fff !important;border: 1px solid #222 !important;}

.btn-black:hover {background-color: #4db2ec !important;color: #fff !important;border: 1px solid #4db2ec !important;}

.btn-blue {background-color: #4db2ec !important;color: #fff !important;border: 1px solid #4db2ec !important;}

.btn-blue:hover {background-color: #222 !important;color: #fff !important;border: 1px solid #222 !important;}


/* Slider *************************************************************************************************************/

.post-slider .post-block .image-wrap {padding-block-end: 450px;}
/* END Slider *********************************************************************************************************/



/* Blog Post List *****************************************************************************************************/


.post-list .post-item.post-holder {margin: 0 0 30px;padding: 0;display: block;}

.post-list .post-container {display: -ms-flexbox;display: flex;}

/* Post Image ************************************/

.post-list .post-image {-ms-flex: 0 0 30%;flex: 0 0 30%;width: 30%;order: 0;}

.post-list .post-image .image-container {position: relative;}

.post-list .post-image .image-wrap {display: block;line-height: 0;overflow: hidden;position: relative;padding-block-end: 70%;}

.post-list .post-image .image-wrap .bg-img {position: absolute;display: block;width: 100%;height: 100%;background-size: cover;background-position: center;}

.post-list .post-image .image-wrap img {width: 100%;height: auto;vertical-align: middle;}
/* Post Category */

.post-list .post-image .post-category {position: absolute;inset-inline-start: 0;inset-block-end: 0;display: block;font-size: 0;line-height: 1;}

.post-list .post-image .post-category a {display: inline-block;margin: 0 5px 0 0;padding: 5px 10px;background: #000;color: #fff;font-size: 11px;line-height: 12px;font-weight: 500;text-decoration: none;text-transform: uppercase;
    /*-webkit-transition: all 0.3s;-ms-transition: all 0.3s;transition: all .3s ease;*/}

.post-list .post-image .post-category a:hover {background: #4db2ec;}

/* Post Info **************************************/

.post-list.modern .post-info {padding: 0 0 0 25px;flex: 0 0 70%;max-width: 70%;width: 70%;order: 1;box-sizing: border-box;}
/* Post Title */

.post-list .post-info .post-title-wrap h3 {margin: 0;}

.post-list .post-info .post-title-wrap h3 a {font-size: 20px;line-height: 25px;font-weight: 500;}
/* Post Data */

.post-list .post-info .post-data-wrap {font-size: 11px;font-family: sans-serif;}

.post-list .post-info .post-data-wrap .post-date {font-size: 11px; margin: 0;padding: 0;box-shadow: none;float: none;width: auto;}

.post-list .post-info .post-data-wrap a {font-weight: 600;}
/* Post Description */

.post-list .post-description {line-height: 21px;font-size: 13px;}
/* Post View */

.post-list .post-view {display: block;background: #000;color: #fff;font-size: 10px;font-weight: 600;text-align: center;line-height: 11px;position: relative;inset-block-start: -5px;padding: 4px 6px;}

.post-list .post-view::after {position: absolute;inset-inline-start: 0;inset-block-end: -3px;content: '';width: 0;height: 0;border-style: solid;border-width: 3px 3px 0 0;border-color: #000 transparent transparent;}
/*.post-list .post-view {display: block;background: #000;color: #fff;font-size: 10px;font-weight: 600;text-align: center;line-height: 11px;position: relative;top: -5px;padding: 4px 6px;}*/
/*.post-list .post-view:before {display: inline-block;content: "\f06e";width: 10px;height: 10px;text-align: center;margin-right: 3px;*/
/*    font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}*/
/* Read More */
/*  .post-list .post-read-more {display: none;}  */

.post-read-more a.post-read {display: inline-block;color: #fff;background-color: #4db2ec;font-size: 13px;font-weight: 500;line-height: 1;padding: 10px 15px;}

.post-read-more a.post-read:hover {color: #fff;background: #222222;}
/*@media (max-width: 1200px) {
    .blog-index-index .post-list /*.post-read-more *//* {display: none;}
}
*/

@media (max-width: 768px) {
    .post-list .post-item.post-holder {margin-block-end: 1.5rem;}
}

@media (max-width: 767px) {
    .post-list .post-image .post-category {display: none;}

    .post-list .post-info {padding: 0 0 0 15px;}

    .post-list.modern .post-description {display: none;}

    .post-read-more a.post-read {display: none;}
}

@media (max-width: 640px) {
    .post-list .post-info .post-title-wrap h3 a {font-size: 14px;font-weight: 600;line-height: 1.4;display: inline-block;
        /*max-height: 39px;overflow: hidden;*/}
}


/* Images Format *******************************************/

.post-image .image-wrap._16x9 {padding-block-end: 35% !important;}

.post-image .image-wrap._4x3 {padding-block-end: 67% !important;}

.post-image .image-wrap._3x2 {padding-block-end: 55% !important;}

.post-image .image-wrap._1x1 {padding-block-end: 100% !important;}

/* Post View **********************************************************************************************************/
/* Page Title */
/*.blog-post-view .page-title-wrapper .page-title {font-size: 35px;line-height: 1.2;margin-bottom: 20px;}*/
/* Category */

.blog-post-view .post-category a {display: inline-block;margin: 0 5px 0 0;padding: 5px 10px;background: #000;color: #fff;font-size: 11px;line-height: 12px;font-weight: 500;text-decoration: none;text-transform: uppercase;}

/* Post Data */

.blog-post-view .post-data-wrap {font-size: 13px;font-family: sans-serif;margin-block-end: 30px;}

.blog-post-view .post-data-wrap a {font-weight: 600;}

.blog-post-view .post-data {color: #bbbbbb;}
/* Post View */
/* Post Comments */

.blog-post-view .post-comments {margin-inline-end: 20px;}
/* Image */

.blog-post-view .post-featured-image {font-size: 0;margin-block-end: 20px;}
/* Tags */

.blog-post-view .post-tag {margin-block-end: 20px;}

.blog-post-view .post-tag .post-tag-title {display: inline-block;padding: 7px 12px;border: 1px solid #222;background: #222;color: #fff;line-height: 1;margin: 0 10px 10px 0;text-transform: uppercase;font-size: 11px;}

.blog-post-view .post-tag .post-tags a {display: inline-block;border: 1px solid var(--border-color);color: #222;background: #fff;padding: 7px 12px;margin-inline-end: 10px;line-height: 1;font-weight: 600;letter-spacing: 1px;font-size: 11px;text-transform: capitalize;}

.blog-post-view .post-tag .post-tags a.current,
.blog-post-view .post-tag .post-tags a:hover {text-decoration: none;opacity: .9;background: #333;color: #fff;}

/* Post Text */

.blog-post-view .post-description {margin-block-end: 30px;}

.blog-post-view .post-description p {font-size: 1.4rem;line-height: 1.7;}

/* Post Sharing Bottom */

.post-sharing-bottom {padding: 30px 0;margin-block-end: 30px;border-top: 1px solid var(--border-color);border-bottom: 1px solid var(--border-color);}

.post-sharing-bottom .addthis_inline_share_toolbox {text-align: center;}

/* Post Gallery */
/*.blog-post-view .post-gallery {display: flex;flex-wrap: wrap;}*/

.blog-post-view .post-gallery {display: grid;grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));grid-gap: 1rem;margin-block-end: 20px;}

.blog-post-view .post-gallery .gallery-image-hld {display: inline-block;}

/* Post Navigation */

.blog-post-view .post-nextprev {display: flex;padding: 20px 0;margin-block-end: 30px;border-top: 1px solid var(--border-color);border-bottom: 1px solid var(--border-color);}

.blog-post-view .post-nextprev .nextprev-link {display: block;font-size: 20px;color: #222222;font-weight: 600;}

.blog-post-view .post-nextprev .nextprev-link:hover {text-decoration: none;}

.blog-post-view .post-nextprev .nextprev-link span {display: block;color: #bbbbbb;font-size: 14px;font-weight: normal;}

/* Block Related */

.blog-post-view .post-bottom .block.related {margin-block-end: 0;}

.blog-post-view .post-bottom .block.related .block-title {margin: 0 0 25px;padding: 5px 0;border-bottom: 1px solid #222222;line-height: 1.42857143;}

.blog-post-view .post-bottom .block.related .block-title strong {border-bottom: 3px solid #222222;padding-block-end: 2px;font-weight: 400;font-size: 18px;}

/* Related Posts */

.mfblog-related-posts-block.block.related .post-list {display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;
    position: relative;overflow: hidden;margin-inline: -10px;}

.mfblog-related-posts-block.block.related .post-list .post-item {-webkit-flex-direction: column;-ms-flex-direction: column;flex-direction: column;margin-block-end: 2rem !important;-ms-flex: 0 0 25%;flex: 0 0 25%;max-width: 25%;padding-inline: 10px;width: 25%; box-sizing: border-box;}

.mfblog-related-posts-block.block.related .post-list .post-item .image-container {
    -ms-flex: 0 0;flex: 0 0;width: 100%;display: block;order: 0;margin-block-end: 10px;}

.mfblog-related-posts-block.block.related .post-list .image-container .image-wrap {display: block;line-height: 0;overflow: hidden;position: relative;padding-block-end: 70%;}

.mfblog-related-posts-block.block.related .post-list .image-container .image-wrap .bg-img {position: absolute;display: block;width: 100%;height: 100%;background-size: cover;background-position: center;}

.mfblog-related-posts-block.block.related .post-list .post-item .block-info {padding-inline: 0 !important;}

.mfblog-related-posts-block.block.related .post-list .block-info h3.post-title a {font-size: 14px !important;line-height: 20px !important;font-weight: 600 !important;color: #222222;}

@media (max-width: 767px) {
    .mfblog-related-posts-block.block.related .post-list .post-item {flex: 0 0 50%;max-width: 50%;}
}

/* Related Products */

.mfblog-related-products-block.block.related .block-title {margin: 0 0 25px;padding: 5px 0;border-bottom: 1px solid #222222;line-height: 1.42857143;}

.mfblog-related-products-block.block.related .block-title strong {border-bottom: 3px solid #222222;padding-block-end: 2px;font-weight: 400;font-size: 18px;}

/* Block Comments */

.block.comments .c-count {margin: 0 0 20px;padding: 0;border-bottom: 1px solid #222222;font-weight: 400;}

.block.comments .c-count strong {border-bottom: 3px solid #222222;padding-block-end: 2px;font-weight: 400;font-size: 18px;display: inline-block;}
/* END Post Bottom *************************************/

@media (max-width: 767px) {
    .blog-post-view .page-title-wrapper .page-title {font-size: 20px;}

    .blog-post-view .post-nextprev .nextprev-link {font-size: 14px;line-height: 1.4;}
}
/* END Post View ******************************************************************************************************/


/* Animation Zoom */

.image-wrap .animation-type-zoom {display: block;line-height: 0;overflow: hidden;position: relative;}

.image-container .animation-type-zoom {-webkit-transition: transform .3s ease,opacity .3s;transition: transform .3s ease,opacity .3s;}

.image-container:hover .animation-type-zoom {transform: scale3d(1.1,1.1,1);  -webkit-transform: scale3d(1.1,1.1,1);}


/* Sidebar ************************************************************************************************************/
/* Block Search */

// .widget.blog-search {width: 100%;margin-block-end: 40px;position: relative;float: inline-end;}

// .widget.blog-search .block-content {margin-block-end: 0;}

// .widget.blog-search .block-content .form {position: relative;}

// .widget.blog-search  label {border: 0;clip: rect(0, 0, 0, 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;text-decoration: none;display: inline-block;float: inline-end;}

// .widget.blog-search .control {border-top: 0;margin: 0;padding: 0;clear: both;}

// .widget.blog-search input {position: static;padding-inline-end: 35px;margin: 0;inset-inline-start: -300%;border: 1px solid #bbbbbb;}

// .widget.blog-search .action.search {position: absolute;inset-block: 0;inset-inline-end: 0;height: 100%;z-index: 1;display: inline-block;background: #222222;-moz-box-sizing: content-box;border: 0;box-shadow: none;line-height: inherit;margin: 0;padding: 0 10px;text-decoration: none;text-shadow: none;font-weight: 400;border-radius: 0;}

// .widget.blog-search .action.search::before {-webkit-font-smoothing: antialiased;content: '';margin: 0;vertical-align: top;display: inline-block;overflow: hidden;speak: none;width: 16px;height: 32px;background: url(../images/search-icon-white.svg) no-repeat -10px -7px;background-size: 35px;opacity: 1;}

// .widget.blog-search .action.search > span {border: 0;clip: rect(0, 0, 0, 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;}
/*.blog-search .actions .action.search {background: #222222;right: 0;padding: 0 10px;border-radius: 0;}*/

/* Posts List */

.widget.block-list-posts .post-list .post-item .image-container {
    -ms-flex: 0 0;
    flex: 0 0;
    width: 100%;
    display: block;
    order: 0;
    margin-block-end: 10px;
}
/* Images */
/* Span Background */

.widget.block-list-posts .post-list .image-container .image-wrap {display: block;line-height: 0;overflow: hidden;position: relative;padding-block-end: 70%;}

.widget.block-list-posts .post-list .image-container .image-wrap .bg-img {position: absolute;display: block;width: 100%;height: 100%;background-size: cover;background-position: center;}
/* Post Info */

.widget.block-list-posts .post-list .post-item .block-info .post-item-name {font-size: 14px;line-height: 20px;font-weight: 600;text-decoration: none;color: #333333;}

.widget.block-list-posts .post-list .post-item .block-info .block-date {color: #bbbbbb;}

.widget.block-list-posts .post-list .post-item .block-info .block-date .post-data {font-size: 11px;}

/* Post Grid */

.widget.block-list-posts .post-list .post-item {-webkit-flex-direction: column;-ms-flex-direction: column;flex-direction: column;margin-block-end: 2rem !important;}


.widget.block-list-posts .post-list .post-item .block-info {padding-inline: 0 !important;}
/* END Posts List */

/* Archive */

.widget.block-archive .archive-item-link {font-weight: 600}

/* End Sidebar ********************************************************************************************************/
