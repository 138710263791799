/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.Notification {
    background-color: var(--color-white);
    padding: 16px;

    & p {
        color: var(--color-black);
    }

    &-Button {
        &::after, &::before {
            background-color: var(--color-black);
        }
    }

    &_type {
        &_success {
            border-bottom: 3px solid var(--notification-success-background);
        }

        &_error {
            --notification-error-background: var(--imported_notificationError_background,#e15141);

            border-bottom: 3px solid var(--notification-error-background);
        }

        &_info {
            border-bottom: 3px solid var(--notification-info-background);
        }
    }
}
