/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../hotme-theme/src/style/abstract/variables';
@import '../../../../hotme-theme/src/style/abstract/media';
@import '../../../../hotme-theme/src/style/abstract/button';
@import '../../../../hotme-theme/src/style/abstract/loader';
@import '../../../../hotme-theme/src/style/abstract/icons';
@import '../../../../hotme-theme/src/style/abstract/parts';
@import '../../../../hotme-theme/src/style/abstract/variables.override';
@import '../../../../hotme-theme/src/style/abstract/button.override';

.CartItem {
    &-GiftMessage {
        color: var(--color-black);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-block-end: 12px;
    }
}
