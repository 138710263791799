/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

.CmsPage {
    &-Wrapper {
        &_page_width {
            &_default {
                max-width: var(--content-wrapper-width);

                @include desktop {
                    padding-inline: 52px;
                }

                @include tablet {
                    padding-inline: 32px;
                }

                @include mobile {
                    padding-inline: 16px;
                }
            }
        }
    }
}
