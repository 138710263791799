.header-promo-block {
    @include mobile {
        inset-inline-start: -50%;
    }

    div {
        background-color: var(--secondary-base-color);
        color: var(--color-white);
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;

        @include desktop {
            font-size: 16px;
        }

        img {
            width: 24px;
            height: 24px;
            margin-inline-end: 5px;

            @include desktop {
                margin-inline-end: 8px;
            }
        }

        span {
            height: min-content;
        }
    }
}
