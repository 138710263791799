:root {
    --default-button-padding: 24px;
    --default-button-radius: 0;
    // Primary button
    --button-background: var(--imported_buttons_background_color, var(--secondary-base-color));
    --button-border: var(--imported_buttons_border_color, var(--secondary-base-color));
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, var(--primary-base-color));
    --button-hover-border: var(--imported_hoverButtons_border_color, var(--primary-base-color));
    // Secondary button (Filled)
    --secondary-button-background: var(--imported_hoverButtons_background_color, var(--primary-error-color));
    --secondary-button-border: var(--imported_hoverButtons_border_color, var(--primary-error-color));
    --secondary-button-border-radius: var(--imported_hoverButtons_border_radius, var(--default-button-radius));
    --secondary-button-color: var(--imported_hoverButtons_color, #{$white});
    --secondary-button-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --secondary-button-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --secondary-button-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hover)
    --secondary-button-hover-background: var(--imported_hoverButtons_background_color, var(--secondary-dark-color));
    --secondary-button-hover-border: var(--imported_hoverButtons_border_color, var(--secondary-dark-color));
}

.Button {
    @include Button;
}

button {
    & > * {
        color: var(--color-white);
    }
}
