:root {
    --footer-link-color: #C4C4C4;
}

.footer-contacts-links-block {
    display: grid;

    @include desktop {
        grid-template-columns: repeat(4, 1fr);
    }

    .block {
        &:not(:last-child) {
            margin-block-end: 24px;

            @include desktop {
                margin-inline-end: 20px;
                margin-block-end: 0;
            }
        }

        &-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-white);
            margin-block-end: 12px;

            @include desktop {
                margin-block-end: 20px;
            }
        }

        .footer-logo {
            max-width: 145px;
            max-height: 48px;

            @include desktop {
                max-width: 242px;
                max-height: 80px;
            }
        }

        ul {
            li {
                margin-block-end: 12px;
                
                &, span, a {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--footer-link-color);
                }

                &::before {
                    display: none;
                }
            }
        }

        &-socials {
            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            a {
                display: flex;
                align-items: center;
            }

            img {
                width: 18px;
                height: 18px;
                margin-inline-end: 8px;
            }
        }

        &-contacts {
            a {
                text-decoration: underline;
            }
        }
    }
}
