/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.sidebar {
    .widget {
        &.block-list-posts {
            .block-content {
                .item {
                    line-height: 1.42857143;

                    .post-image {
                        z-index: 1;

                        .post-item-link {
                            img {
                                width: 100%;
                                height: auto;
                                vertical-align: middle;
                            }
                        }
                    }

                    .post-item-content {
                        display: flex;
                        flex-direction: column;

                        .post-item-date {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
