/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

:root {
    --select-option-color: #50505033;
}

.FieldSelect {
    .ChevronIcon {
        inset-inline-end: 20px;
    }

    &-Option {
        font-size: 14px;
        padding-inline-start: 20px;

        &:hover,
        &:focus {
            @include desktop {
                background: var(--select-option-color);
            }
        }

        &_isHovered {
            @include desktop {
                background: var(--select-option-color);
            }
        }
    }
}
