/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.PageBuilderSlider {
    height: 100%;

    .pagebuilder-poster-content {
        height: 100%;

        img {
            width: auto;
        }
    }

    h1 {
        min-height: var(--h1-line-height);
    }

    p {
        min-height: var(--paragraph-line-height);
        margin-block-end: 0;
    }

    .Slider {
        --translateX: 0;
        --translateY: 0;
        --animation-speed: 0;
        --slider-height: 600px;
        --slide-height: 0;
        --slide-width: 0;
        --height-transition-speed: 0;

        height: 100%;
        min-height: none;
        max-width: 100%;
        overflow: hidden;
        touch-action: pan-y;
        transition: height var(--height-transition-speed);

        &-Wrapper {
            display: flex;
            align-items: flex-start;
            height: 100%;
            will-change: transform;
            transition: transform var(--animation-speed);
            transform: translate3d(var(--translateX), var(--translateY), 0);

            > * {
                flex-grow: 1;
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                opacity: var(--slideOpacity);
                position: var(--slidePosition);
                transition: var(--animation-speed);

                > * {
                    height: 100%;

                    > * {
                        height: 100%;

                        > * {
                            height: 100%;
                        }
                    }
                }
            }

            &_isVertical {
                flex-direction: column;
            }
        }

        &-Image {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-Counter {
            font-size: 1.2rem;
            color: var(--color-white);
            background: var(--color-dark-gray);
            border-radius: 15px;
            padding: 1px 4px 0;
            position: absolute;
            inset-block-end: 5px;
            inset-inline-end: 5px;
        }

        &-Crumbs {
            height: auto;
            min-height: 16px;
            position: absolute;
            margin: auto;
            display: grid;
            grid-gap: 4px;
            width: 100%;
            grid-template-columns: repeat(auto-fit, 16px);
            justify-content: center;
            inset-block-end: 12px;

            @include desktop {
                grid-gap: 16px;
            }
        }

        &-Crumb {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--primary-base-color);
            will-change: width, height;
            transition: width 300ms, height 300ms;

            &_isActive {
                width: 16px;
                height: 16px;
            }
        }

        &-Arrow {
            cursor: pointer;
            position: absolute;
            inset-block-start: 50%;
            width: 35px;
            height: 35px;

            .ChevronIcon {
                width: 100%;
                height: 100%;
            }

            &_isPrev {
                inset-inline-start: 0;

                &:dir(ltr) {
                    transform: rotate(180deg) translateY(50%) scale(-1);
                }

                &:dir(rtl) {
                    transform: translateY(50%);
                }
            }

            &_isNext {
                inset-inline-end: 0;

                &:dir(ltr) {
                    transform: translateY(-50%);
                }

                &:dir(rtl) {
                    transform: rotate(180deg) translateY(-50%) scale(-1);
                }
            }

            &_isDisabled {
                pointer-events: none;
                opacity: .5;
            }
        }
    }
}
