/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

/* stylelint-disable scss/operator-no-newline-before */

:root {
    --header-total-height: calc(
        var(--header-height)
        + var(--offline-notice-height)
        + var(--demo-notice-height)
        + env(safe-area-inset-top)
        + var(--header-delivery-information-height)
    );
}

.Header {
    &-CmsPromoWrapper {
        @include mobile {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 50%;
        }
    }

    &-DeliveryInformation {
        max-width: none;
    }
}
