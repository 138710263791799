/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.PostPage {
    .post-bottom {
        margin-block-start: 30px;

        .post-gallery {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            grid-gap: 1rem;
            margin: 15px 0;
            margin-block-end: 20px;

            .gallery-image-hld {
                display: flex;
                margin: 0 14px 14px 0;
                border: 1px solid #ccc;
                float: inline-start;
                max-width: 200px;
                max-height: 133px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                    border: 0;
                    display: flex;
                    justify-self: center;
                    align-self: center;
                }
            }
        }

        .post-nextprev-hld {
            margin-block-end: 30px;

            .nextprev-link {
                font-weight: 700;
            }

            .next-link {
                float: inline-end;
            }

            .prev-link {
                float: inline-start;
            }
        }

        .block.related {
            margin-block-end: 0;

            .block-title {
                display: block;
                margin: 0 0 25px;
                padding: 0 5px 0 0;
                border-bottom: 1px solid #222222;
                line-height: 1.42857143;

                strong {
                    border-bottom: 3px solid #222222;
                    padding-block-end: 2px;
                    font-weight: 400;
                    font-size: 18px;
                }
            }

            ol {
                margin-block: 0rem 2.5rem;
                padding-inline-start: 40px;

                > li {
                    margin-block: 0rem 1rem;

                    &::before {
                        inset-block-start: 0;
                        inset-inline-start: -1em;
                    }
                }
            }

            .products {
                margin: 30px 0;

                .product-items {
                    font-size: 0;
                    letter-spacing: -1px;
                    line-height: 0;
                    margin-inline-start: 0;
                    padding-inline-start: 0;

                    .ProductCard {
                        display: inline-block;
                        margin-inline-start: 2%;
                        width: calc((100% - 2%) / 2);

                        &:nth-child(2n+1) {
                            margin-inline-start: 0;
                        }

                        @media (min-width: 640px) {
                            display: inline-block;
                            margin-inline-start: 2%;
                            width: calc((100% - 4%) / 3);

                            &:nth-child(2n+1) {
                                margin-inline-start: 2%;
                            }

                            &:nth-child(3n+1) {
                                margin-inline-start: 0;
                            }
                        }

                        @media (min-width: 1024px) {
                            width: calc((100% - 8%) / 5);
                            margin-inline-start: 2%;

                            &:nth-child(3n+1) {
                                margin-inline-start: 2%;
                            }

                            &:nth-child(5n+1) {
                                margin-inline-start: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .Loader {
        position: relative;
        height: 50px;
        margin-block: 40px;
    }
}

.fancybox-container-post-page {
    .fancybox__backdrop {
        opacity: .87;
        background: #1e1e1e;
    }

    .fancybox__thumbs {
        display: none;
    }

    .fancybox__toolbar {
        background: none;

        .carousel__button[disabled] {
            display: none;
        }
    }

    .fancybox__toolbar__items--right {
        inset-block-start: 0;
        inset-inline-end: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        direction: ltr;
        z-index: 99997;
        box-sizing: border-box;
    }

    .carousel__button, .carousel__button:link, .carousel__button:visited {
        color: #ccc;
    }

    .carousel__button {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        width: 44px;
        height: 44px;
        margin: 0;
        padding: 10px;
        border: 0;
        border-radius: 0;
        background: rgba(30,30,30,.6);
        transition: color .3s ease;
        cursor: pointer;
        outline: none;

        svg {
            stroke: #ccc;
        }
    }

    .carousel__button:hover {
        background: #e1e1e1;
        border: 1px solid #cccccc;
        color: #333333;
    }

    .carousel__button:focus, .carousel__button:hover {
        color: #fff;

        svg {
            stroke: #fff;
        }
    }

    .fancybox__nav {
        position: absolute;
        height: 100%;
        inset-block-start: 0;
        width: 100%;

        .carousel__button {
            position: absolute;
            inset-block-start: 50%;
            // margin: -50px 0 0;
            z-index: 99997;
            background: transparent;
            width: 60px;
            height: 100px;
            padding: 17px;

            &:hover {
                border: none;
            }
        }

        .carousel__button::before {
            content: "";
            position: absolute;
            inset-block-start: 30px;
            inset-inline-end: 10px;
            width: 40px;
            height: 40px;
            background: rgba(30,30,30,.6);
        }

        .carousel__button.is-prev {
            inset-inline-start: 0;
        }

        .carousel__button.is-next {
            inset-inline-end: 0;
        }
    }
}

