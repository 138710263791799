:root {
    --background-color: #232222;
    --span-color: var(--primary-error-color);
    --header-delivery-information-height: 60px;

    @include desktop {
        --header-delivery-information-height: 40px;
    }
}

.header-delivery-information {
    padding: 10px 16px;
    text-align: center;
    background-color: var(--background-color);

    p {
        margin-block-end: 0;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-white);

        @include desktop {
            display: inline;
        }
    }

    span {
        font-weight: 700;
        color: var(--span-color);
    }

    a {
        font-weight: 400;
        text-decoration: underline;
    }
}
