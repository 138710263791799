.shop-info-promo-block {
    background-color: var(--primary-light-color);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 32px 16px;

    @include desktop {
        grid-template-columns: repeat(4, 1fr);
        padding: 40px 52px;
    }

    @include tablet {
        padding-inline: 32px;
    }

    & > div {
        text-align: center;

        &:not(:last-child) {
            @include desktop {
                margin-inline-end: 20px;
            }
        }

        &:nth-child(1) {
            &,
            &+.promo-block-item {
                @include mobile {
                    margin-block-end: 32px;
                }
            }
        }

        &:nth-child(odd) {
            @include mobile {
                margin-inline-end: 16px;
            }
        }

        h3 {
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-light-color);
            margin-block: 0 8px;

            @include desktop {
                font-size: 20px;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--color-white);
            margin-block-end: 0;

            @include desktop {
                font-size: 16px;
                line-height: 24px;
            }

            a {
                text-decoration: underline;
                font-weight: 400;
            }

            span {
                font-weight: 700;
            }
        }

        br {
            display: inline;

            @include desktop {
                display: none;
            }
        }

        img {
            width: 48px;
            height: 48px;
            margin-block-end: 16px;
            margin-inline: auto;

            @include desktop {
                width: 74px;
                height: 74px;
                margin-block-end: 24px;
            }
        }
    }
}
