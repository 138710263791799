@mixin Button {
    font-size: 16px;

    &_isSecondary {
        background-color: var(--secondary-button-background);
        border-color: var(--secondary-button-border);
        border-radius: var(--secondary-button-border-radius);
        color: var(--secondary-button-color);
        height: var(--secondary-button-height);
        padding-inline: var(--secondary-button-padding);
        text-decoration: var(--secondary-button-text-decoration);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background-color: var(--secondary-button-hover-background);
                    border-color: var(--secondary-button-hover-border);
                }
            }
        }
    }
}
