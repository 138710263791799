/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.CarouselArrowIcon {
    stroke: var(--primary-base-color);

    &_direction {
        &_left {
            transform: rotate(180deg);

            [dir="rtl"] & {
                transform: none;
            }
        }

        &_right {
            transform: none;

            [dir="rtl"] & {
                transform: rotate(180deg);
            }
        }

        &_bottom {
            transform: rotate(90deg);
        }

        &_top {
            transform: rotate(-90deg);
        }
    }
}
