/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.sidebar {
    .widget {
        &.block-archive {
            .item {
                display: inline-block;

                &:not(:last-child) {
                    &::after {
                        padding-inline-end: 5px;
                    }
                }
            }
        }
    }
}
