/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.post-holder {
    .post-header {
        .post-info {
            padding: 0 0 20px;

            .item {
                margin-inline-start: unset;
                margin-inline-end: unset;
                line-height: 1.42857143;
                font-size: 1.4rem;

                &::after {
                    margin-inline-end: 5px;
                    margin-inline-start: 5px;
                }

                &.item.post-tags,
                &.item.post-categories {
                    a {
                        display: inline-block;
                    }
                }

                *:not(:first-child) {
                    margin-inline-start: 5px;
                }
            }
        }
    }
}
