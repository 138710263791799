.new-label {
    position: absolute;
    z-index: 99;
    background: var(--secondary-base-color);
    width: 109px;
    height: 23px;
    display: flex;
    justify-content: center;
    clip-path: polygon(17% 0%, 74% 0%, 100% 100%, 0% 100%);
    transform: rotate(321deg);
    inset-block-start: 14px;
    inset-inline-start: -19px;

    @include desktop {
        inset-inline-start: -21px;
        clip-path: polygon(18% 0%, 75% 0%, 100% 100%, 0% 100%);
    }

    span {
        color: var(--color-white);
        font-size: 18px;
        line-height: 22px;
        font-weight: 900;
        text-transform: uppercase;
        margin-block-end: 0;
        letter-spacing: 0.08em;
        display: flex;
        align-items: center;
        position: relative;
        inset-block-start: 1px;
    }
}
