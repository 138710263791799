/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

:root {
    --header-button-color: #701611;

    @include desktop {
        --header-nav-height: 48px;
    }
}

.Header {
    background-color: var(--primary-base-color);

    @include desktop {
        border-block-end: 1px solid var(--secondary-light-color);
    }

    &_name {
        &_menu {
            border: none;
        }

        &_pdp,
        &_cms-page {
            .Header {
                &-Title {
                    display: -webkit-box;
                    height: min-content;
                    font-size: 16px;
                    line-height: 18px;
                    line-clamp: 2;
                    white-space: unset;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    &-Nav {
        @include mobile {
            background-color: var(--color-white);
            padding-inline: 0;
            border-bottom: 1px solid var(--primary-divider-color);
        }

        @include desktop {
            max-width: 100%;
            padding-inline: 52px;
        }

        .PDP & {
            @include mobile {
                @media screen and (orientation: landscape) {
                    height: 35px;
                }
            }
        }
    }

    &-LogoWrapper,
    &-CmsPromoWrapper {
        @include mobile {
            width: 140px;
        }
    }

    .header {
        &-promo-block {
            @include mobile {
                inset-inline: -51%;
            }
        }
    }

    &-MobileNav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 12px;
        padding-inline: 16px;
        background-color: var(--primary-base-color);
    }

    &-Button {
        &_type {
            &_minicart {
                height: unset;
                width: unset;
            }
        }
    }

    &-Nav,
    &_isCheckout &-MobileNav {
        justify-content: space-between;
    }

    &_isCheckout {
        @include desktop {
            border-block-end: none;
        }

        .Header {
            &-TopMenu,
            &-MobileNavButton {
                display: none;
            }

            &-Nav {
                display: flex;

                @include desktop {
                    height: 80px;
                }
            }

            &-MobileNav {
                height: 72px;
            }

            &-LogoWrapper,
            &-CmsPromoWrapper {
                position: relative;
                inset-inline-start: unset;
                inset-inline-end: unset;
            }

            &-CmsPromoWrapper {
                @include mobile {
                    padding-block-end: 12px;
                }

                .header-promo-block {
                    inset-inline: unset;
                }
            }

            &-LogoWrapper {
                margin: unset;
            }
        }
    }

    &-MobileNavButton,
    &-MinicartButtonWrapper {
        padding: 12px;
        background-color: var(--header-button-color);

        svg {
            fill: var(--color-white);
            vertical-align: top;

            &:hover {
                @include mobile {
                    fill: var(--color-white);
                }
            }
        }
    }

    &-MinicartItemCount {
        background-color: var(--secondary-dark-color);
    }

    &-LogoWrapper {
        .Image {
            background-color: transparent;
        }

        @include mobile {
            position: absolute;
            margin-block-end: 5px;
        }
    }

    &-CmsPromoWrapper {
        @include mobile {
            position: absolute;
            inset-inline-start: 50%;
            inset-block-start: 8px;
        }
    }

    &-Title {
        inset-block-start: 0;
        font-size: 24px;
        line-height: 32px;

        .PDP & {
            @include mobile {
                @media screen and (orientation: landscape) {
                    font-size: 20px;
                }
            }
        }
    }

    .Menu {
        &-MenuWrapper {
            @include desktop {
                background-color: var(--color-white);
                margin-block-start: 12px;
                max-width: 100%;
                height: 52px;
            }
        }

        &-SubCategoriesWrapper {
            background-color: var(--color-white);
            box-shadow: 0 5px 8px -8px;
        }

        &-MainCategories {
            height: 52px;
        }

        &-Item {
            margin-block: auto;

            @media (max-width: 1339px) {
                margin-inline-start: 0;
                vertical-align: middle;
            }

            @media (min-width: 1009px) {
                height: 100%;
            }

            &Caption {
                color: var(--primary-base-color);
                margin-block: unset;
            }

            &List {
                @include desktop {
                    column-gap: 90px;
                }

                @media (min-width: 1009px) {
                    padding-block: 16px;
                }

                @media (max-width: 1008px) {
                    padding-block: 6px;
                }

                @media (min-width: 811px) {
                    text-align: center;
                    column-gap: 0;
                }

                @media (min-width: 869px) {
                    column-gap: 5px;
                }

                @media (min-width: 904px) {
                    column-gap: 20px;
                }

                @media (min-width: 1104px) {
                    column-gap: 32px;
                }

                @media (max-width: 1023px) {
                    margin-inline: 20px;
                }

                @media (min-width: 1440px) {
                    column-gap: 30px;
                }
            }
        }
    }

    &-News {
        color: var(--color-white);

        .ExclamationMarkIcon {
            fill: var(--color-white);
        }
    }
}
