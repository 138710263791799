.CmsPage {
    [data-appearance='collage-centered'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-inline-start: auto;
                margin-inline-end: auto;
            }
        }
    }
}
