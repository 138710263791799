.homepage-promo-banner {
    width: 100%;
    overflow-x: hidden;

    .pagebuilder-mobile-hidden {
        @include mobile {
            display: none;
        }
    }

    .pagebuilder-mobile-only {
        @include desktop {
            display: none;
        }
    }

    [data-content-type='button-item'] {
        background-color: var(--secondary-light-color);
        color: var(--color-white);
        padding-inline: 24px;
        padding-block: 12px;
        line-height: 24px;
        font-size: 16px;
        display: inline-block;

        &:hover {
            background-color: var(--secondary-dark-color);
        }

        a {
            color: var(--color-white);
            font-size: 16px;
        }
    }

    [data-content-type='image'] {
        position: absolute;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;

        img {
            object-fit: cover;

            @include mobile {
                object-position: 75%;
            }
        }
    }

    [data-content-type='text'],
    [data-content-type='buttons'] {
        margin-inline: 16px;

        @include desktop {
            margin-inline: 165px;
        }
    }

    [data-content-type='text'] {
        margin-block-end: 24px;

        @include desktop {
            margin-block-end: 44px;
        }

        h1 {
            @include desktop {
                font-size: 40px;
                line-height: 44px;
            }
        }

        &:first-of-type {
            margin-block-start: 46px;

            @include desktop {
                margin-block-start: 88px;
            }
        }
    }

    [data-content-type='buttons'] {
        margin-block-end: 78px;

        @include desktop {
            margin-block-end: 106px;
        }
    }
}
