/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

.Footer {
    &-Content {
        background-color: #232323;
    }

    &-Columns {
        padding: 32px 16px;

        @include desktop {
            padding: 60px 52px;
        }
    }

    &-ColumnTitle {
        color: var(--color-white);
    }

    &-ColumnItem {
        color: var(--footer-text-color);
    }

    &-CopyrightContent {
        background-color: var(--title-color);
        display: flex;
        align-items: center;
        padding: 14px 52px;

        &Wrapper {
            background-color: var(--title-color);
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--footer-link-color);
        }
    }
}
