.homepage-trend-preview {
    padding-inline: 48px;
    background: var(--color-white);
    margin-block-start: 48px;
    margin-block-end: 20px;
    margin-inline: 0;

    @include desktop {
        margin-block-start: 80px;
        margin-block-end: 40px;
    }

    @include mobile {
        padding-inline: 14px;
    }

    h2 {
        margin: 0;
        font-size: 24px;
        line-height: 32px;

        @include desktop {
            margin-block-end: 8px;
            font-size: 32px;
            line-height: 36px;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;

        @include desktop {
            font-size: 20px;
        }
    }
}
