$error-color: #fbb13c;
$success-color: #7ed688;
$info-color: #fbb13c;
$title-color: #0a0a0a;
$text-color: #383838;
$text-secondary-color: #949494;
$notice-background-color: #e0e0e0;

:root {
    --primary-error-color: #{$error-color};
    --primary-success-color: #{$success-color};
    --primary-info-color: #{$info-color};
    --title-color: #{$title-color};
    --body-content-color: #{$text-color};
    --body-content-secondary-color: #{$text-secondary-color};
    --footer-text-color: #c4c4c4;
    --notice-background-color: #{$notice-background-color};
}
