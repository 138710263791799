:root {
    --input-border-color-focus: var(--primary-error-color);
    --input-border-radius: var(--imported_input_border_radius, 0);
    --input-color: var(--imported_input_color, var(--title-color));
}

input,
textarea,
select {
    caret-color: var(--input-color);
}
