:root {
    --gradient-color: #ffffff0d;
}

.pricing-promo {
    margin-block: 48px;
    padding-inline: 16px;

    @include desktop {
        max-width: 884px;
        margin: auto;
        margin-block: 80px;
        padding-inline: 0;
    }

    @include tablet {
        padding-inline: 32px;
    }

    h1 {
        text-align: center;
        margin-block-end: 40px;

        @include mobile {
            font-size: 24px;
            line-height: 32px;
            margin-block-end: 20px;
        }
    }

    .promo-blocks-wrapper {
        display: flex;
        justify-content: space-between;
        margin-block-end: 20px;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            margin-block-end: 40px;
        }

        & > div {
            background: var(--primary-base-color);
            text-align: center;
            padding: 12px 0;
            margin-block-end: 16px;

            &:last-child {
                margin-block-end: 0;
            }

            @include desktop {
                padding: 20px 0 24px;
                width: 273px;
                margin-block-end: 0;
            }

            h3 {
                color: var(--primary-error-color);
                font-size: 16px;
                font-weight: 800;
                margin-block: 0;

                @include desktop {
                    font-size: 20px;
                    margin-block-end: 8px;
                }
            }

            p {
                color: var(--color-white);
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                margin-block-end: 0;

                @include desktop {
                    font-size: 20px;
                }
            }
        }
    }

    .promo-content {
        h2 {
            margin-block-start: 0;
            margin-block-end: 12px;
            text-align: start;

            @include mobile {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .expandable-content {
            height: 245px;
            overflow: hidden;

            @include desktop {
                height: auto;
                overflow: initial;
            }

            p {
                @include mobile {
                    font-size: 14px;
                    line-height: 20px;
                }

                &:last-of-type {
                    margin-block-end: 0;
                }
            }
        }

        input {
            position: absolute;
            inset-block-start: 100%;
            width: 100%;
            z-index: 4;
            opacity: 0;

            &:checked {
                ~ .expandable-content {
                    height: 100%;
                }

                + .label {
                    display: none;
                }

                ~ .gradientbottom {
                    display: none;
                }
            }
        }

        .label {
            position: absolute;
            inset-block-start: 100%;
            color: var(--primary-error-color);
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            text-align: center;
            margin-block-start: 12px;

            &::before {
                content: "Show more";
            }

            img {
                width: 10px;
                height: 6px;
                margin-inline-start: 10px;
                margin-block-end: 2px;
            }

            @include desktop {
                display: none;
            }
        }

        .gradientbottom {
            position: absolute;
            inset-block-end: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(180deg, var(--gradient-color) 0%, var(--color-white) 100%);

            @include desktop {
                display: none;
            }
        }
    }
}
