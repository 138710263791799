/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.Menu {
    &-MenuWrapper {
        margin-block-start: 24px;
    }

    &-ItemCaption {
        @include desktop {
            color: var(--color-white);
            text-transform: capitalize;
        }

        &_type {
            &_main {
                font-weight: 400;
            }
        }
    }

    &Page {
        @include mobile {
            margin-block-start: 190px;
        }
    }

    &-SubCategories {
        &Wrapper {
            display: none;
            
            &_isVisible {
                display: inherit;
            }
        }
    }
}
