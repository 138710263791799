/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

.Breadcrumb {
    $crumb-padding: 15px;

    padding-inline-end: $crumb-padding;

    .ChevronIcon {
        margin-inline-start: $crumb-padding;
    }

    &:last-child {
        :link {
            color: var(--breadcrumbs-active-color);
        }
    }

    &:nth-last-child(2) {
        .ChevronIcon {
            filter: invert(82%) sepia(38%) saturate(1483%) hue-rotate(328deg) brightness(101%) contrast(97%);
        }
    }

    &-Link {
        font-weight: bold;
    }
}
