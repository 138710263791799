/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../hotme-theme/src/style/abstract/variables';
@import '../../../../hotme-theme/src/style/abstract/media';
@import '../../../../hotme-theme/src/style/abstract/button';
@import '../../../../hotme-theme/src/style/abstract/loader';
@import '../../../../hotme-theme/src/style/abstract/icons';
@import '../../../../hotme-theme/src/style/abstract/parts';
@import '../../../../hotme-theme/src/style/abstract/variables.override';
@import '../../../../hotme-theme/src/style/abstract/button.override';

.AmastyGiftItem {
    .ProductConfigurableAttributeDropdown {
        @include desktop {
            width: auto;
        }
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include desktop {
            padding-block-start: 10px;
            padding-block-end: 0;
            padding-inline: 15px;
        }
    }

    &-Figure {
        flex-grow: 1;
        padding: 15px;
    }

    &-Picture {
        padding-block-end: 100%;

        @media only screen and (max-width: 400px) {
            padding-block-end: 50%;
        }
    }
}
