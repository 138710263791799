/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.NotificationIcon {
    margin-inline-end: 12px;

    &_isSuccess {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--notification-success-background);
        border-radius: 50%;
        width: 20px;
        height: 20px;

        & svg {
            stroke: var(--notification-success-background);
        }
    }

    &_isError {
        --notification-error-color: var(--imported_notificationError_color,#e15141);

        fill: var(--notification-error-color);
    }

    &_isInfo {
        fill: var(--notification-info-background);
    }
}
