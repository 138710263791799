/* stylelint-disable declaration-no-important */

:root {
    /**
    * Had to use !important
    * The --link-color/hover are being overridden in the MyAccountDownloadableTableRow for some reason
    */
    --link-color: var(--imported_link_color, var(--primary-error-color)) !important;
    --link-hover: var(--imported_linkHover_color, var(--secondary-dark-color)) !important;
    --default-link-underline: 1px solid var(--link-color);
    --default-hover-link-underline: 1px solid var(--link-hover);
}

a {
    font-size: inherit;

    &.Link {
        border-block-end: var(--default-link-underline);

        &:hover,
        &:focus {
            padding-block-end: 0;
        }
    }
}
