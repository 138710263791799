.no-route-wrapper {
    padding-block-end: 28px;

    h1 {
        color: var(--title-color);
        margin-block-end: 24px;
    }

    & > div {
        p {
            color: var(--title-color);
            margin-block-end: 19px;

            &:last-of-type {
                margin-block-end: 0;
            }
        }

        ul {
            li {
                color: var(--title-color);
                margin-block-end: 9px;

                &:last-child {
                    margin-block-end: 28px;
                }

                a {
                    color: var(--link-color);
                    font-weight: 400;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
