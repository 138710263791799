/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

.StyleGuidePage {
    &-Color {
        width: 60px;
        height: 60px;

        &#title {
            background-color: var(--title-color);
        }

        &#text {
            background-color: var(--body-content-color);
        }

        &#secondaryText {
            background-color: var(--body-content-secondary-color);
        }
    }

    &-ColorRow {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 60px;
        margin-block-end: 20px;
    }

    &-ColorLabel,
    &-ColorValue {
        margin-inline-start: 12px;
        margin-block: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &-ColorValue {
        color: var(--body-content-secondary-color);
    }

    &-DesktopContentWrapper {
        padding-inline: 52px;
    }

    &-MobileContentWrapper {
        padding-inline: 16px;
    }

    &-TabletContentWrapper {
        border: 1px solid var(--primary-base-color);
        max-width: 1024px;
        padding-inline: 32px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-ActiveLink {
        border-bottom: 1px solid var(--secondary-dark-color);
    }

    &-HoveredInput {
        &[type='checkbox'], &[type='radio'] {
            & + .input-control {
                border-color: var(--checkbox-border-color-active);
            }
        }
    }

    .Button {
        &_isSecondary {
            &.Button {
                &_isHovered {
                    background-color: var(--secondary-button-hover-background);
                    border-color: var(--secondary-button-hover-border);
                }
            }
        }
    }
}
