/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';

:root {
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--primary-error-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color_active, var(--secondary-dark-color));
    --disabled-checkbox-color: var(--imported_disabled_checkboxes_color, var(--body-content-secondary-color));
    --disabled-checkbox-border-color: var(--imported_disabled_checkboxes_border_color, #e0e0e0);
    --disabled-checkbox-background-color: var(--imported_disabled_checkboxes_background_color, #5050500d);
    --radio-button-border-width-active: var(--imported_radio_button_border_width_active, 5px);
    --disabled-input-background-color: var(--imported_disabled_input_background_color, #5050500d);
}

.Field {
    &-Label {
        padding-block-end: 12px;

        &_isRequired {
            color: var(--primary-base-color);
        }
    }

    &-CheckboxLabel, &-RadioLabel {
        span {
            font-size: 14px;
            line-height: 20px;
            color: var(--body-content-color);
        }

        &:hover {
            @include desktop {
                color: unset;
            }
        }
    }

    &-ErrorMessages {
        margin-block-start: 4px;
        line-height: 16px;
    }

    select::placeholder {
        opacity: 0.5;
    }

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            input,
            select {
                height: 48px;

                &:focus {
                    &::placeholder {
                        opacity: 0;
                    }
                }

                &:disabled {
                    background-color: var(--disabled-input-background-color);

                    &::placeholder,
                    & ~ .ChevronIcon {
                        opacity: 0.2;
                    }
                }
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        & + .input-control {
            border: 1px solid var(--checkbox-border-color);
            margin-inline-end: 12px;
            margin-block-end: 2px;
            transition-property: background-color, border-color, border-width;
        }

        &:checked {
            & + .input-control {
                border-color: var(--checkbox-border-color);
            }
        }

        &:disabled {
            cursor: auto;

            & + .input-control {
                --box-color: var(--disabled-checkbox-background-color);

                border-color: var(--disabled-checkbox-border-color);
            }

            & ~ span {
                color: var(--disabled-checkbox-color);
            }
        }
    }

    [type='checkbox'] {
        &:hover {
            & + .input-control {
                &::after {
                    @include desktop {
                        --checkmark-color: transparent;
                    }
                }
            }
        }

        &:checked {
            & + .input-control {
                --box-color: var(--checkbox-border-color);

                &::after {
                    --checkmark-color: var(--color-white);
                }
            }

            &:disabled {
                & + .input-control {
                    --box-color: var(--disabled-checkbox-border-color);
                }
            }
        }
    }

    [type='radio'] {
        & + .input-control {
            &::after {
                content: none;
            }
        }

        &:checked {
            & + .input-control {
                border-width: var(--radio-button-border-width-active);
            }

            &:disabled {
                & + .input-control {
                    --box-color: var(--color-white);
                }
            }
        }
    }
}
