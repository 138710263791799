/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Copyright © Magefan (support@magefan.com). All rights reserved.
 * Please visit Magefan.com for license details (https://magefan.com/end-user-license-agreement).
 *
 * Glory to Ukraine! Glory to the heroes!
 *
 * Original source: vendor/magefan/module-blog/view/frontend/web/css
 * Most Parts of these styles are copied from original extension Magefan Blog
 */

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.post-holder {
    padding-block-end: 20px;
    margin-block-end: 20px;
}

.blog-page-list .post-holder {border-bottom: 1px solid #eee}

.post-holder .post-header {position: relative;}

.post-holder .post-header .post-info {padding: 0 0 20px;}

.post-holder .post-header .post-info .item {display: inline-block}

.post-holder .post-header .post-info .dash {display: none; /* fix for old templates */}

.post-holder .post-header .post-info .label {font-weight: 700;}

.post-holder .post-header .post-info .value {font-style: italic;}

.post-holder .post-header .post-info .item::after {display: inline-block; content: ' | ';color: #b9b9b9}

.post-holder .post-header .post-info .item:last-child::after {display: none}

.post-list {
    margin-block: 0px 20px;
    list-style: none;
}

.post-list-wrapper .post-list {padding-inline-start: 0}

.post-list .post-content .post-read-more {padding-block-start: 10px; float: inline-end;}

.subcategory-links-wrapper ul,
.post-links-wrapper ul {list-style: none}

.post-content .post-description img {margin: 15px 0;max-width: 100%;height: auto}

.post-content .post-description ul,
.post-content .post-description ol {list-style-position: outside;}

.post-content .post-description .mfblog-contents li {display: list-item;}

.post-content .post-description .mfblog-contents li li {margin-inline-start: 15px;}

.post-content .post-description .post-ftimg-hld {margin: 0 0 10px;}

.post-content .post-description .post-ftimg-hld img {width: 100%; padding: 0; margin: 0;}

.post-content .post-description .mfblog-contents-toc {list-style: decimal;}

.post-content .post-description .mfblog-contents-toc li {margin-block-end: 1rem;}

.post-content .post-description .mfblog-contents-toc li > ol {margin-block-start: 1rem;}

.post-content .post-description .mfblog-contents-toc li::before {content: none;}

.post-content .post-description .mfblog-contents-toc li a {display: inline;}


/* Post Title */

.post-list .post-header .post-title-holder {display: flex;align-items: center;justify-content: space-between;flex-wrap: wrap;}

.post-list .post-header .post-title {margin: 0;padding: 10px 0;}

.post-list .post-header .post-title a {text-decoration: none;font-size: 22px;font-weight: 400;}

.post-list .post-holder .post-header .addthis_toolbox a {margin-block-end: 0;}

.post-list .hidden {display: none;}

.post-view .post-bottom {margin-block-start: 30px;}

.post-view .post-nextprev-hld {margin-block-end: 30px;}

.post-view .post-nextprev-hld .nextprev-link {font-weight: 700;}

.post-view .post-nextprev-hld .prev-link {float: inline-start}

.post-view .post-nextprev-hld .next-link {float: inline-end}

/* Post Gallery */

.post-view .post-gallery {margin: 15px 0}

.post-view .post-gallery .gallery-image-hld {margin: 0 14px 14px 0; border: 1px solid #ccc; display: block; float: inline-start}

.post-view .post-gallery .gallery-image {padding: 0; margin: 0; display: block}

.post-view .block {margin-block-end: 30px;}

/* Widget Gallery */

.widget.block-categories ul {padding: 0;margin: 0;list-style: none;}

.widget.block-categories ul li {padding: 5px 0 5px 9px;margin: 0;}

.widget.block-categories ul li a {font-weight: 600;}

.widget.block-categories ul ul {margin: 0;}

/* Widget RSS */

.widget.block-rss .rss-icon {float: inline-start; width: 23px; padding-inline-end: 10px;}

/* Widget Search */

.widget.blog-search {width: 100%;margin-block-end: 40px;position: relative;float: inline-end;}

.widget.blog-search .block-content {margin-block-end: 0;}

.widget.blog-search .block-content .form {position: relative;}

.widget.blog-search label {border: 0;clip: rect(0, 0, 0, 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;text-decoration: none;display: inline-block;float: inline-end;}

.widget.blog-search .control {border-top: 0;margin: 0;padding: 0;clear: both;}

.widget.blog-search input {position: static;padding-inline-end: 35px;margin: 0;inset-inline-start: -300%;}


.widget.blog-search .action.search > span {border: 0;clip: rect(0, 0, 0, 0);height: 1px;margin: -1px;overflow: hidden;padding: 0;position: absolute;width: 1px;}

/* Widget Tag Claud */

.widget.block-tagclaud .smallest {font-size: 0.75em}

.widget.block-tagclaud .small {font-size: 1em}

.widget.block-tagclaud .medium {font-size: 1.25em}

.widget.block-tagclaud .large {font-size: 1.5em}

.widget.block-tagclaud .largest {font-size: 2em}

/* Widget Archive */

.widget.block-archive .item {margin: 5px 0;}


.widget.block-archive .item::after {content: "\\"; display: inline-block;padding-inline-start: 5px}

/* Widget Recent */

.blog-widget-recent .post-list {padding: 0;margin: 0;}

.blog-widget-recent .post-holder {width: 31.2%;float: inline-start;margin: 1%;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}

.post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder {width: 48%;}

.post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder:nth-child(2n+1) {clear: both;}

@media (max-width: 767px) {
    .post-list-wrapper.blog-widget-recent:not(.blog-widget-recent-masonry) .post-holder {width: 98%;}
}

@media (max-width: 1024px) {
    .blog-widget-recent .post-holder {
        width: 48%;
    }
}

@media (max-width: 767px) {
    .blog-widget-recent .post-holder {
        width: 98%;
    }
}

.blog-widget-recent .post-description .post-text-hld {
    min-height: 150px;
    overflow-y: auto;
}

.blog-widget-recent-masonry .post-description .post-text-hld {
    max-height: none;
    min-height: 0;
}

.blog-widget-recent-masonry .post-description .post-ftimg-hld {
    width: 100%;
    margin: 0;
}

.mfblog-autoloader {text-align: center;}

.mfblog-autoloader .mfblog-show-onload {display: none;}


@media (max-width: 420px) {
    #mfblog_gcomments {max-width: 345px; overflow-y: scroll;}
}

.blog-category-view .category-content,
.blog-tag-view .tag-content {padding-inline-start: 0}

/* Posts List */

.widget.block-list-posts .block-content .item {
    padding: 8px 0 0;
}

.widget.block-list-posts .block-content .item .post-image {
    width: 60px;
    float: inline-start;
    border-radius: 0;
    padding: 2px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    margin-inline-end: 12px;
}

.widget.block-list-posts .block-content .item .post-image .post-item-link {
    display: inline-block;
}

.widget.block-list-posts .block-content .item .post-image .post-item-link img {
    width: 100%;
    height: auto;
    vertical-align: middle;
}
/* END Posts List */

/* Fix Related Products */

.blog-post-view .products-grid .product-item {
    display: inline-block;
    margin-inline-start: 2%;
}

.blog-post-view .product-item:nth-child(2n+1) {
    margin-inline-start: 0;
}

.blog-post-view .products-grid .product-item .product-item-info {
    width: auto;
}

@media (min-width: 640px) {
    .blog-post-view .products-grid .product-item {
        display: inline-block;
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(2n+1) {
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(3n+1) {
        margin-inline-start: 0;
    }
}

@media (min-width: 1024px) {
    .blog-post-view .products-grid .product-item {
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(3n+1) {
        margin-inline-start: 2%;
    }

    .blog-post-view .products-grid .product-item:nth-child(5n+1) {
        margin-inline-start: 0;
    }
}

@media only screen and (max-width: 768px) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

@media (min-width: 769px) {
    .pagebuilder-mobile-only {
      display: none !important;
    }
}