/* stylelint-disable scss/at-import-partial-extension */
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../packages/@scandipwa/hotme-theme/src/style/abstract/button.override';

.CarouselScrollArrow {
    $arrow-size: 44px;

    background: var(--color-white);
    opacity: 0.8;
    width: $arrow-size;
    height: $arrow-size;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;

    &:hover {
        @include desktop {
            transform: rotate(-180deg);
        }
    }

    &_isNextArrow {
        &:hover {
            @include desktop {
                transform: none;
            }
        }
    }

    &_isInvisible {
        visibility: visible;

        svg {
            stroke: var(--secondary-dark-color);
        }
    }
}
