/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.sidebar-additional {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    box-sizing: border-box;

    .block {
        .Loader {
            position: relative;
            min-height: 50px;
        }
    }

    @media (min-width: 768px), print {
        width: 20.83333333%;
        float: inline-end;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
        box-sizing: border-box;
        clear: inline-end;
        padding-inline-start: 2%;
    }
}

.sidebar {
    .block {
        margin-block-end: 40px;

        @media (min-width: 768px), print {
            margin-block-end: 50px;
        }
    }

    .widget {
        clear: both;

        &:not(:last-child) {
            margin-block-end: 40px;
        }

        &.block {
            &:not(:last-child) {
                margin-block-end: 40px;
            }
        }

        .block-title {
            margin: 0 0 20px;
            text-transform: capitalize;

            strong {
                font-size: 18px;
                font-weight: 300;
                vertical-align: middle;
            }
        }
    }
}
