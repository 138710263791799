:root {
    // h1 desktop
    --h1-font-size: var(--imported_h1_font_size, 32px);
    --h1-color: var(--imported_h1_color, var(--title-color));
    --h1-line-height: var(--imported_h1_line_height, 36px);

    // h1 mobile
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 32px);
    --h1-color-mobile: var(--imported_h1Mobile_color, var(--title-color));
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 36px);

    // h2 desktop
    --h2-font-size: var(--imported_h2_font_size, 24px);
    --h2-color: var(--imported_h2_color, var(--title-color));
    --h2-line-height: var(--imported_h2_line_height, 32px);

    // h2 mobile
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 24px);
    --h2-color-mobile: var(--imported_h2Mobile_color, var(--title-color));
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 32px);

    // h3 desktop
    --h3-font-size: var(--imported_h3_font_size, 20px);
    --h3-font-weight: var(--imported_h3_font_weight, 800);
    --h3-color: var(--imported_h3_color, var(--title-color));
    --h3-line-height: var(--imported_h3_line_height, 24px);

    // h3 mobile
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 20px);
    --h3-font-weight-mobile: var(--imported_h3Mobile_font_weight, 800);
    --h3-color-mobile: var(--imported_h3Mobile_color, var(--title-color));
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 24px);

    // paragraph
    --paragraph-font-size: var(--imported_paragraph_font_size, 16px);
    --paragraph-color: var(--imported_paragraph_color, var(--body-content-color));
    --paragraph-line-height: var(--imported_paragraph_line_height, 24px);

    // paragraph mobile
    --paragraph-font-size-mobile: var(--imported_paragraphMobile_font_size, 16px);
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, var(--body-content-color));
    --paragraph-line-height-mobile: var(--imported_paragraphMobile_line_height, 24px);

    // caption
    --caption-font-size: var(--imported_caption_font_size, 14px);
    --caption-color: var(--imported_caption_color, var(--body-content-color));
    --caption-line-height: var(--imported_caption_line_height, 20px);

    // caption mobile
    --caption-font-size-mobile: var(--imported_captionMobile_font_size, 14px);
    --caption-color-mobile: var(--imported_captionMobile_color, var(--body-content-color));
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 20px);
}

body {
    color: var(--body-content-color);
}

li {
    & * {
        color: var(--body-content-color);
    }
}
