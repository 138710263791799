.homepage-shopping-and-delivery-promo {
    color: var(--color-white);
    text-align: center;
    background-color: var(--secondary-base-color);
    margin-block-start: 48px;
    display: flex;
    flex-wrap: wrap;
    padding-block-end: 32px;
    padding-inline: 16px;

    @include desktop {
        padding: 8px 52px 40px;
    }

    @include tablet {
        padding-inline: 32px;
    }

    @include desktop {
        margin-block-start: 80px;
    }

    & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: calc((100% - 15px) / 2);
        margin-block-start: 32px;

        &:nth-child(2n) {
            @include mobile {
                margin-inline-start: 15px;
            }
        }

        @include desktop {
            flex-direction: column;
            width: calc((100% - 60px) / 4);
            margin-inline-start: 20px;
            padding-inline: 20px;
        }

        &:first-child {
            @include desktop {
                margin-inline-start: 0;
            }
        }

        h3 {
            color: var(--primary-info-color);
            margin: 16px 0 8px;
            display: flex;
            align-items: center;

            @include mobile {
                font-size: 16px;
            }

            @include desktop {
                margin-block-start: 24px;
                height: 48px;
            }
        }

        span {
            line-height: 20px;

            @include desktop {
                line-height: 24px;
                font-size: 16px;
            }
        }

        img {
            width: 48px;
            height: 48px;

            @include desktop {
                width: 74px;
                height: 74px;
            }
        }
    }
}
