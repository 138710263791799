:root {
    --iphone-website-preview-box-shadow-color: #0000001a;
}

.video-app-promo-block {
    max-width: 1110px;
    margin-inline: auto;
    margin-block-start: 48px;

    @include desktop {
        margin-block-start: 80px;
    }

    p {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding-inline: 16px;

        @include desktop {
            font-weight: 800;
            font-size: 20px;
            padding-inline: 0;
        }

        span {
            color: var(--primary-base-color);
        }

        &:last-of-type {
            margin-block-end: 0;
        }
    }

    & > div {
        display: flex;
        margin-block-end: 32px;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }

    .promo-block {
        &-video {
            padding-inline: 16px;
            margin-block-end: 32px;

            @include desktop {
                padding-inline: 0;
                margin-block-end: 0;
            }

            h2 {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                margin-block-start: 0px;
                margin-block-end: 20px;
                margin-inline: 41px;
                text-transform: unset;

                @include desktop {
                    font-size: 32px;
                    line-height: 36px;
                    margin-block-end: 32px;
                    margin-inline: 0;
                }
            }

            @include desktop {
                margin-inline-end: 32px;
            }

            iframe {
                height: 194px;
                width: 100%;

                @include desktop {
                    display: block;
                    min-width: 658px;
                    height: 370px;
                }

                @include tablet {
                    min-width: 540px;
                    height: 370px;
                }
            }
        }

        &-app {
            background: linear-gradient(to top, var(--primary-base-color) 84.47%, var(--color-white) 15.53%);
            width: 100%;
            display: flex;
            justify-content: center;

            img {
                max-width: 149px;
                max-height: 300px;
                width: 100%;
                filter: drop-shadow(0px 6px 16px var(--iphone-website-preview-box-shadow-color));

                @include desktop {
                    max-width: 196px;
                    max-height: 396px;
                }
            }
        }
    }
}
