.checkout-notice-block {
    background: var(--notice-background-color);
    padding: 24px 16px;

    p {
        color: var(--body-content-color);
        margin-block-end: 0;
    }

    h2 {
        color: var(--title-color);
        margin-block-end: 12px;
        margin-block-start: 0;
    }
}
