/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/media';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/loader';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/icons';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/parts';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/variables.override';
@import '../../../../../@scandipwa/hotme-theme/src/style/abstract/button.override';

/**
 * Magefan Blog compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.sidebar {
    .widget {
        &.block-tagclaud {
            .tagclaud-hld {
                span {
                    display: inline-block;
                    padding-inline-end: 5px;
                }
            }

            .largest {
                font-size: 2em;
            }

            .large {
                font-size: 1.5em;
            }

            .medium {
                font-size: 1.25em;
            }

            .small {
                font-size: 1em;
            }

            .smallest {
                font-size: .75em;
            }

            span {
                a {
                    font-size: inherit;
                }
            }
        }
    }
}
